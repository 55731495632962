<template>
   <v-expand-transition>
      <v-form ref="form">
         <div v-if="typeof option.id === 'string' || option.id >= 0">
            <v-card-text class="pt-9">
               <v-row>
                  <v-col cols="12" md="3">
                     <v-select v-model="form.feature" :items="sequence.features" label="Feature Name" placeholder="Pilih Fitur" outlined hide-details required :rules="[v => !!v]"
                        :return-object="false" :menu-props="{ bottom: true, offsetY: true }"
                        />
                  </v-col>
                  <v-col cols="12" md="4">
                     <v-text-field v-model="form.name" label="Sequence Name" placeholder="Nama Urutan" outlined hide-details required :rules="[v => !!v]" />
                  </v-col>
               </v-row>
               <v-row>
                  <v-col cols="12" md="3">
                     <v-select v-model="sequence.code" :items="sequence.codes.filter(j => j.features.includes(form.feature))" label="Actor Code" placeholder="Kode Pelaku" outlined hide-details
                        :return-object="false" :menu-props="{ bottom: true, offsetY: true }"
                        />
                  </v-col>
                  <v-col cols="12" md="4">
                     <v-select v-model="sequence.flow" :items="sequence.flows.filter(j => j.features.includes(form.feature))" label="Activity Flow" placeholder="Alur Aktivitas" outlined hide-details
                        :return-object="false" :menu-props="{ bottom: true, offsetY: true }"
                        >
                        <template v-slot:append-outer>
                           <v-btn icon large height="56" width="56" tile :disabled="!sequence.code || !sequence.flow || !!sequences.find(j => j.name === 'Complete')" @click="insertSequence">
                              <v-icon size="60" color="secondary">
                                 mdi-plus-box-outline
                              </v-icon>
                           </v-btn>
                        </template>
                     </v-select>
                  </v-col>
               </v-row>
               <v-row v-if="sequences.length" class="mt-0 mb-n6">
                  <v-col cols="12">
                     <v-chip-group active-class="accent--text" column>
                        <v-chip v-for="(tag, idx) in sequences" :key="idx" large label outlined close style="max-width: 190px;"
                           :color="tag.name === 'Approved' ? 'teal darken-1' : tag.name === 'Rejected' ? 'red darken-2' : ''"
                           @click:close="sequences.splice(idx, 1)"
                           >
                           <v-icon left size="32">
                              {{ `mdi-numeric-${idx + 1}-circle-outline` }}
                           </v-icon>
                           <v-list-item-content>
                              <v-list-item-title class="mb-0" style="line-height: 1.4rem;">
                                 {{ tag.name }}
                              </v-list-item-title>
                              <v-list-item-subtitle>
                                 {{ sequence.codes.find(j => j.value === tag.code).text }}
                              </v-list-item-subtitle>
                           </v-list-item-content>
                        </v-chip>
                     </v-chip-group>
                  </v-col>
               </v-row>
            </v-card-text>
            <v-divider />
            <v-card-text class="pb-6">
               <v-row class="mt-0">
                  <v-col cols="12" class="py-0 d-flex">
                     <v-btn v-if="$hasRole(option.kind.toLowerCase(), 'CU')" x-large color="accent" depressed class="mr-4" :loading="$store.state.process === 'updateOption'" @click="updateRecord">
                        Update
                     </v-btn>
                     <v-btn x-large color="accent" depressed outlined class="mr-4" @click="option.id = -1">
                        {{ $store.state.process === 'updateOption' ? 'Close' : 'Cancel' }}
                     </v-btn>
                     <v-spacer />
                  </v-col>
               </v-row>
            </v-card-text>
            <v-divider />
         </div>
      </v-form>
   </v-expand-transition>
</template>

<script>
import { } from '@/utils/nirtara'
import { } from '@/utils/fields.js'
const empty = { kind: 'Sequence', name: '', feature: '' }
export default {
   name: 'NSequenceForm',

   props: {
      option: { type: Object },
   },
   data: () => ({
      isUsed: false,
      form: Object.assign({}, empty),
      sequence: { features: [], codes: [], code: '', flows: [], flow: '' },
      sequences: [],
   }),

   computed: {
   },
   watch: {
      'option.id' (id) {
         if (id < 0) return
         id === 0 && this.$refs.form.reset()
         this.form = Object.assign({}, empty, typeof id === 'string' ? this.option : { id: 0 })
         this.sequence.code = this.sequence.flow = ''
         this.sequences = []
         if (id !== 0) {
            // this.option.Flows && (this.sequences = this.option.Flows) // use this when updating sequence DONE
            this.option.Flows && (this.sequences = this.option.Flows.map(j => Object.assign(j, { name: j.activity || j.name }))) // j.activity comes from previous coding
         }
      },
   },
   created () {
      const { sequence } = this
      sequence.features = [
         { text: 'Approval', value: 'APV' },
         { text: 'Invitation', value: 'INV' },
      ]
      sequence.codes = [
         // { text: 'Super Administrator', value: 'SUP', features: [] },
         // { text: 'Site Administrator', value: 'STA', features: [] },
         { text: 'Visitor Browser', value: 'VST', features: ['APV', 'INV'] },
         { text: 'Occupant Tenant', value: 'TNT', features: ['APV', 'INV'] },
         { text: 'Guest Reception', value: 'RCP', features: ['APV'] },
         { text: 'System Decision', value: 'SYS', features: ['INV'] },
      ]
      sequence.flows = [
         { text: 'Register', features: ['APV', 'INV'] },
         { text: 'Modified', features: ['INV'] },
         { text: 'Rejected', features: ['APV'] },
         { text: 'Verified', features: ['APV'] },
         { text: 'Approved', features: ['APV', 'INV'] },
         { text: 'Complete', features: ['APV', 'INV'] },
      ]
   },
   methods: {
      insertSequence () {
         const { sequence, sequences } = this
         sequences.push({ Flow: [], code: sequence.code, name: sequence.flow })
         sequence.code = sequence.flow = ''
         for (var idx = sequences.length; idx > 9; idx--) sequences.splice(0, 1)
      },
      updateRecord () {
         if (!this.$refs.form.validate()) return
         this.$emit('update', Object.assign(this.form, { Flows: this.sequences }))
      },
   },
}
</script>

<style lang="scss" scoped>
.col-12 {
   padding: 0 12px 20px 12px;
}
::v-deep .v-text-field--outlined .v-input__append-outer {
   margin: auto 0 auto 16px;
}
::v-deep .v-chip .v-chip__close.v-icon {
   font-size: 22px !important;
}
::v-deep .v-chip .v-chip__content {
   letter-spacing: 0;
}
.v-btn:not(.v-btn--round).v-size--x-large {
   min-width: 115px;
}
</style>
